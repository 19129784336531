import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";

export const ClientError = () => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={5}
      mt={10}
    >
      <Typography>
        <div style={{ textAlign: "center" }}>
          클라이언트 에러가 발생했습니다.
          <br />
          에러가 지속되면, 웹팀에 문의해주세요.
        </div>
      </Typography>
      <Stack direction="row" spacing={2}>
        <Button
          onClick={() => {
            window.history.back();
          }}
          variant="contained"
        >
          이전 페이지로 돌아가기
        </Button>
      </Stack>
    </Stack>
  );
};
