import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, ReactNode, useEffect } from "react";
import MenuList from "./MenuList";
import AppBar from "layout/AppBar";
import AppDrawer from "layout/drawer/AppDrawer";
import Main from "layout/main";

const drawerWidth = 200;

interface Props {
  children: ReactNode;
}

export default function Layout({ children }: Props) {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState<boolean>(true);

  useEffect(() => {
    if (isMobileSize) {
      setOpen(false);
    }
  }, [isMobileSize]);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ minHeight: "100vh" }}>
      <AppBar
        open={open}
        handleDrawer={handleDrawer}
        drawerWidth={drawerWidth}
      />
      <AppDrawer
        open={open}
        drawerWidth={drawerWidth}
        handleDrawer={handleDrawer}
      >
        <MenuList />
      </AppDrawer>
      <Main open={open} isMobileSize={isMobileSize}>
        <Toolbar variant="dense" />
        {children}
      </Main>
    </Box>
  );
}
