import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import RetryError from "components/fallback/RetryError/RetryError";

export const AsyncErrorBoundary = ({ children }) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={(props) => <RetryError {...props} />}
    >
      {children}
    </ErrorBoundary>
  );
};
