import { AxiosError } from "axios";
import { useRouter } from "next/router";
import { useState, useEffect, ReactElement } from "react";

import { useCookies } from "react-cookie";
import { useRecoilState } from "recoil";
import { tokens } from "configs/tokens";
import { useLogin } from "features/login/hooks/useLogin";
import useAccountInfoBaseQuery from "hooks/queries/useAccountInfoBaseQuery";
import { useAlertSnackbar } from "layout/hooks/useAlertSnackbar";
import { AccountState } from "store/accountRecoil";

interface Props {
  children: ReactElement;
}

const AuthProvider = ({ children }: Props) => {
  const [cookies] = useCookies([tokens.ACCESS_TOKEN_NAME]);
  const cookieAccessToken = cookies[tokens.ACCESS_TOKEN_NAME] as string;
  const cookieRefreshToken = cookies[tokens.REFRESH_TOKEN_NAME] as string;
  const { handleClose } = useAlertSnackbar();
  const { refreshToken, onAuthError } = useLogin();

  const router = useRouter();
  const { accountInfo } = useAccountInfoBaseQuery({
    enabled: cookieAccessToken === undefined ? false : true,
    onError: (error: AxiosError) => {
      onAuthError(error);
      handleClose();
    }
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [account, setAccountState] = useRecoilState(AccountState);

  useEffect(() => {
    if (cookieAccessToken) {
      if (accountInfo) {
        setAccountState(accountInfo);
      }
    }
  }, [cookieAccessToken, accountInfo]);

  useEffect(() => {
    if (router.isReady) {
      if (!cookieAccessToken && !cookieRefreshToken) {
        router.push("/login");
      }

      if (!cookieAccessToken && cookieRefreshToken) {
        refreshToken(cookieRefreshToken);
      }
    }
  }, [router.isReady]);

  useEffect(() => {
    if (checkPagePermission(router.pathname)) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
  }, [router.pathname, account]);

  const checkPagePermission = (url: string): boolean => {
    const publicPaths = ["/login"];
    const path = url.split("?")[0];

    if (!account && !publicPaths.includes(path)) {
      // 현재 login 여부만 체크 추후 user.level 연동 필요
      return false;
    } else {
      return true;
    }
  };

  return <>{isLoaded && children}</>;
};

export default AuthProvider;
