import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import DenseItemIcon from "components/common/DenseItemIcon";
import { MenuItemProps } from "types/layout.type";

function MenuItem({
  icon,
  title,
  isSelected,
  typhographySxProps
}: MenuItemProps) {
  return (
    <ListItem disablePadding selected={isSelected}>
      <ListItemButton dense sx={{ pl: 2 }}>
        {icon && <DenseItemIcon>{icon}</DenseItemIcon>}
        <ListItemText>
          <Typography sx={{ fontSize: icon ? 13 : 16, ...typhographySxProps }}>
            {title}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

export default MenuItem;
