const mode = process.env.NEXT_PUBLIC_MODE;

const getTokenName = (prefix: "access" | "refresh") => {
  if (mode === "production") return `${prefix}_token_prod`;
  if (mode === "staging") return `${prefix}_token_staging`;
  return `${prefix}_token_dev`;
};

const ACCESS_TOKEN_NAME = getTokenName("access");
const REFRESH_TOKEN_NAME = getTokenName("refresh");

export const tokens = {
  ACCESS_TOKEN_NAME,
  REFRESH_TOKEN_NAME
};
