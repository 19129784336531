import { styled } from "@mui/material/styles";
const drawerWidth = 200;

interface MainProps {
  open: boolean;
  isMobileSize: boolean;
}

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isMobileSize"
})<MainProps>(({ theme, open, isMobileSize }) => ({
  flexGrow: 1,
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: isMobileSize ? 0 : drawerWidth
  })
}));

export default Main;
