import { atom, useRecoilState } from "recoil";

export const successSnackbarState = atom({
  default: false,
  key: "SuccessSnackbarState"
});

export const successMessageState = atom({
  default: "",
  key: "SuccessMessageState"
});

export function useSuccessSnackbar() {
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] =
    useRecoilState(successSnackbarState);
  const [successMessage, setSuccessMessage] =
    useRecoilState(successMessageState);
  const handleClose = () => {
    setIsSuccessSnackbarOpen(false);
  };
  const openSuccessSnackbar = (message: string) => {
    setSuccessMessage(message);
    setIsSuccessSnackbarOpen(true);
  };

  return {
    isSuccessSnackbarOpen,
    openSuccessSnackbar,
    handleClose,
    successMessage
  };
}
