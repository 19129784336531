import axios, { AxiosHeaders } from "axios";
import { Cookies } from "react-cookie";

import { settings } from "./settings";
import { tokens } from "./tokens";

const instance = axios.create({
  baseURL: settings.API_DOMAIN
});

const cookies = new Cookies();

instance.interceptors.request.use((config) => {
  const access_token = cookies.get(tokens.ACCESS_TOKEN_NAME);

  if (!config.headers) {
    config.headers = new AxiosHeaders();
  }
  config.headers.Authorization = `Bearer ${access_token}`;

  return config;
});

export default instance;
