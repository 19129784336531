import { SetterOrUpdater, useRecoilState } from "recoil";
import { RefreshStatus } from "store/refreshStatusRecoil";
import { RefreshToken } from "types/refreshTokenStatus";

const useRefreshingStatus = (): [
  RefreshToken,
  SetterOrUpdater<RefreshToken>
] => {
  const [refreshStatus, setRefreshStatus] = useRecoilState(RefreshStatus);

  return [refreshStatus, setRefreshStatus];
};

export default useRefreshingStatus;
