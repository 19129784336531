import Link from "next/link";
import MenuItem from "layout/drawer/MenuItem";
import { LinkMenuItemProps } from "types/layout.type";

function LinkMenuItem({
  icon,
  title,
  to,
  isSelected,
  typhographySxProps
}: LinkMenuItemProps) {
  return (
    <Link href={to}>
      <a>
        <MenuItem
          icon={icon}
          title={title}
          isSelected={isSelected}
          typhographySxProps={{ ...typhographySxProps }}
        />
      </a>
    </Link>
  );
}

export default LinkMenuItem;
