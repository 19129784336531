import { getFetcher, postFetcher } from "configs/fetchers";
import { Account, SigninBody } from "types/account.type";

export const getAccountInfo = () => {
  return getFetcher<Account>("/v1/account");
};

export const signout = async () => {
  return postFetcher("/v1/account/signout");
};

export const signin = async (body: SigninBody) => {
  return postFetcher<Account>("/v1/account/signin", body);
};
