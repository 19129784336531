import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { useLogin } from "features/login/hooks/useLogin";

export type NetworkErrorProps = {
  handleRoutePageWithoutQuery: () => void;
  resetErrorBoundary: () => void;
  error: AxiosError;
};

export const NetworkError = ({
  handleRoutePageWithoutQuery,
  resetErrorBoundary,
  error
}: NetworkErrorProps) => {
  const { onAuthError } = useLogin(resetErrorBoundary);

  useEffect(() => {
    const status = error?.response?.status;
    const isAuthError = status === 401 || status === 460;

    isAuthError && onAuthError(error);
  }, []);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={5}
      mt={10}
    >
      <Typography>
        <div style={{ textAlign: "center" }}>
          데이터를 불러오는데 실패하였습니다.
        </div>
      </Typography>
      <Stack direction="row" spacing={2}>
        <Button onClick={handleRoutePageWithoutQuery} variant="contained">
          페이지로 돌아가기
        </Button>
        <Button onClick={() => resetErrorBoundary()} variant="outlined">
          다시 시도
          <RefreshIcon color="primary" sx={{ ml: 1 }} />
        </Button>
      </Stack>
    </Stack>
  );
};
