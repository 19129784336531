import { RoleSlugEnum } from "op-business";
import { atom } from "recoil";

export interface AccountType {
  access_id: string;
  in_testing: boolean;
  level: number;
  name: string;
  role: string;
  role_slug: RoleSlugEnum;
}

export const AccountState = atom<AccountType | null>({
  default: null,
  key: "AccountState"
});
