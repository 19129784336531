import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { SxProps, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import DenseItemIcon from "components/common/DenseItemIcon";
import { Menu } from "types/layout.type";

interface Props extends Menu {
  children: React.ReactNode;
  defaultExpanded: boolean;
  sx?: SxProps<Theme>;
  typhographySxProps?: SxProps<Theme>;
}

function ExpandableMenu({
  title,
  icon,
  children,
  defaultExpanded,
  sx,
  typhographySxProps
}: Props) {
  const [open, setOpen] = useState(defaultExpanded);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick} sx={sx} dense>
        {icon && <DenseItemIcon>{icon}</DenseItemIcon>}
        <ListItemText>
          <Typography sx={{ fontSize: icon ? 13 : 16, ...typhographySxProps }}>
            {title}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
}

export default ExpandableMenu;
