import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import { LinkMenuItemProps } from "types/layout.type";

function NestedMenuItem({ icon, title, to, isSelected }: LinkMenuItemProps) {
  return (
    <Link href={to}>
      <ListItem disablePadding selected={isSelected}>
        <ItemButton dense>
          <SubMenuRightAligned>
            <ListItemText>
              <Typography sx={{ fontSize: 13 }}>{title}</Typography>
            </ListItemText>
            <ListItemIcon sx={{ minWidth: 0 }}>{icon}</ListItemIcon>
          </SubMenuRightAligned>
        </ItemButton>
      </ListItem>
    </Link>
  );
}

export default NestedMenuItem;

const ItemButton = styled(ListItemButton)`
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
`;

const SubMenuRightAligned = styled("div")`
  flex-basis: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
