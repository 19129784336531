import ListItemIcon from "@mui/material/ListItemIcon";

interface Props {
  children: React.ReactNode;
}

function DenseItemIcon({ children }: Props) {
  return (
    <ListItemIcon
      sx={{
        minWidth: 0,
        mr: 2
      }}
    >
      {children}
    </ListItemIcon>
  );
}

export default DenseItemIcon;
