import { UseQueryOptions } from "@tanstack/react-query";
import { accountQueryKeys } from "constants/account.constant";

import { useQuery } from "hooks/useQuery";
import { getAccountInfo } from "network/account/accountApi";
import { Account } from "types/account.type";

const useAccountInfoBaseQuery = (options?: UseQueryOptions<Account>) => {
  const { data, error, isFetching } = useQuery<Account>(
    accountQueryKeys.accountInfoAll,
    getAccountInfo,
    options
  );

  return {
    accountInfo: data,
    accountInfoError: error,
    accountInfoFetching: isFetching
  };
};

export default useAccountInfoBaseQuery;
