import React from "react";
import { BaseAlertDialog, useSystemDialog } from "shared-ui";

export default function SystemAlertDialog() {
  const { title, content, open, onConfirm } = useSystemDialog();

  return (
    <BaseAlertDialog
      open={open === "alert"}
      title={title}
      onConfirm={onConfirm}
      content={content}
    ></BaseAlertDialog>
  );
}
