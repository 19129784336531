import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import { useAuth } from "./hooks/useAuth";

function LogoutButton() {
  const { postSignout } = useAuth();
  return (
    <ListItem disablePadding>
      <ListItemButton dense onClick={postSignout}>
        <ListItemText>
          <Typography sx={{ fontSize: 13 }}>로그아웃</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

export default LogoutButton;
