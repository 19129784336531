import { useRouter } from "next/router";
import { OpMenu } from "op-business";
import { settings } from "configs/settings";
import ExpandableMenu from "layout/drawer/ExpandableMenu";
import LinkMenuItem from "layout/drawer/LinkMenuItem";
import NestedMenuItem from "layout/drawer/NestedMenuItem";

interface Props {
  menus: OpMenu[];
}

function MenuListItems({ menus }: Props) {
  const host = settings.ORIGIN_OPS;
  const router = useRouter();

  const isSamePath = (menu) => {
    const { url, isMigrated } = menu;
    const urlWithoutQuery = url.split("/?")[0];

    return router.pathname === urlWithoutQuery && isMigrated === true;
  };

  return (
    <>
      {menus.map((menu) => {
        if (!menu.children) {
          return (
            <LinkMenuItem
              title={menu.title}
              typhographySxProps={{ fontWeight: 600 }}
              icon={<i className={`fa-solid fa-lg ${menu.icon}`}></i>}
              key={menu.title}
              to={
                menu.isMigrated === false
                  ? `${host}${menu.url}`
                  : menu.url ?? ""
              }
              isSelected={isSamePath(menu)}
            />
          );
        }
        return (
          <ExpandableMenu
            key={menu.title}
            title={menu.title}
            icon={<i className={`fa-solid fa-lg ${menu.icon}`}></i>}
            defaultExpanded={true}
            sx={{ bgcolor: "action.hover" }}
            typhographySxProps={{ fontWeight: 600 }}
          >
            {menu.children.map((sub) => {
              if (!sub.children) {
                return (
                  <LinkMenuItem
                    key={sub.title}
                    title={sub.title}
                    icon={<i className={`fa-solid fa-lg ${sub.icon}`}></i>}
                    to={
                      sub.isMigrated === false
                        ? `${host}${sub.url}`
                        : sub.url ?? ""
                    }
                    isSelected={isSamePath(sub)}
                  />
                );
              }
              return (
                <ExpandableMenu
                  key={sub.title}
                  title={sub.title}
                  icon={<i className={`fa-solid fa-lg ${sub.icon}`}></i>}
                  defaultExpanded={sub.children
                    .map((subChild) => subChild.url)
                    .includes(router.pathname)}
                >
                  {sub.children.map((subChild) => (
                    <NestedMenuItem
                      key={subChild.title}
                      title={subChild.title}
                      icon={<i className={`fa-solid ${subChild.icon}`}></i>}
                      to={
                        subChild.isMigrated === false
                          ? `${host}${subChild.url}`
                          : subChild.url ?? ""
                      }
                      isSelected={isSamePath(subChild)}
                    />
                  ))}
                </ExpandableMenu>
              );
            })}
          </ExpandableMenu>
        );
      })}
    </>
  );
}

export default MenuListItems;
