import { SetterOrUpdater, useRecoilState } from "recoil";
import { AppLayoutState } from "store/layoutRecoil";
import { AppLayout } from "types/appLayout.type";

const useAppLayoutState = (): [AppLayout, SetterOrUpdater<AppLayout>] => {
  const [appLayoutState, setAppLayoutState] = useRecoilState(AppLayoutState);

  return [appLayoutState, setAppLayoutState];
};

export default useAppLayoutState;
